import * as React from "react"
import Container from '../components/container.js'
import styled from 'styled-components'
import "../App.css"

import "@fontsource/montserrat";
import "@fontsource/eb-garamond";

// styles
const Body = styled.div`
  position: fixed;
  font-size: 18px;
  font-family: EB Garamond;
  font-weight: 300;
  width: 600px;
  text-align: justify;
  
  @media (max-width: 850px) {
    width: 425px;
    font-size: 17px;
  }

  @media (max-width: 500px) {
    width: 300px;
    font-size: 16px;
    text-align: left;

    height: 100vh;
    overflow: scroll;
  }
`
const IndexPage = () => {
  return (
    <Container>
        <div class="homeBlurb scrollbar">
        Hello! My name is Kevin, and I write on topics in philosophy and law. I recently completed my DPhil in Philosophy at the University of Oxford, where I wrote a thesis on the nature of normativity under the supervision of Roger Crisp and Ruth Chang, and I am currently a JD candidate at Yale Law School. I previously completed the BPhil at Oxford and received my AB from Princeton.

        <br/> <br/>I am currently working on some papers on jurisprudence and normativity. I also have interests in the history of philosophy, constitutional theory, international law, and political theory. 

        <br/> <br/>Thank you so much for visiting! You can find some more information on my work from the other tabs, including some classical piano I have recorded.
        <br/> <br/>And please feel free to reach me at <span><a href="mailto:kevin.a.zhang@gmail.com">kevin.a.zhang@gmail.com</a></span>!
        
        <span><div style={{padding: '200px'}}/></span>

        </div>

    </Container>
  )
}

export default IndexPage
