import * as React from "react"
import {Link} from "gatsby"
import styled from 'styled-components'
import bckg from '../images/lower_res.jpg'

import "@fontsource/montserrat";
import "@fontsource/montserrat/600.css";
import "@fontsource/eb-garamond/400.css";

const Background = styled.main`
  background-image: url(${bckg});
  position: fixed;
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
`
const InnerContainer = styled.div`
  padding-top: 22vh;
  padding-left: 20vw;
  padding-right: 200px;

  @media (max-width: 850px) {
    padding-left: 10vw;
    padding-right: 0px;
  }

  @media (max-width: 500px) {
    padding-left: 7vw;
    padding-top: 3vh;
  }
`
  
const Header = styled.h1`
  font-family: EB Garamond;
  font-weight: 300;
  font-size: 30px;
` 

// inline styles, not styled components. 
const linkStyle = {
  fontFamily: 'montserrat',
  fontSize: 16,
  textTransform: 'uppercase',
  opacity: '38%',
  paddingRight: 30,
  color: 'black',
  textDecoration: 'none' 
}

const activeLinkStyle = {
  color: '#468C00',
  fontWeight: 600,
  opacity: '100%',
  textDecoration: 'underline',
  textUnderlineOffset: '3px'
}

const StyledLink = (props) => {
  return (
    <Link 
      style={linkStyle} 
      activeStyle={activeLinkStyle} 
      to={props.to}>
        {props.children}
    </Link>
  )
}

const Container = ({children}) => {
    return (
      <div style={{width: '100%', height: '100%'}}>
          <style jsx global>{`
            body {
              margin: 0px;
              padding: 0px;
            }
          `}</style>
          <Background />
          <InnerContainer>
            {/* header */}
            <div style={{position: 'fixed'}}>
              <Header>Kevin A. Zhang</Header>
              <nav>
                  <StyledLink to='/'>HOME</StyledLink>
                  <StyledLink to='/works'>WORKS</StyledLink>
                  <StyledLink to='/music'>MUSIC</StyledLink>
              </nav>
            </div>

            <div style={{padding:70}}/>

            {/* content */}
            {children}
          </InnerContainer>
        {/* </OuterContainer> */}
      </div>
    )
}

export default Container;